<template>
  <BaseLayout>
    <div class="mx-auto mb-[80px] sm:mb-[120px] sm:mt-[64px] lg:min-w-[1024px]">
      <h1 class="mb-[17px] text-gpx_2xl font-semibold text-lms_black-500">
        Manage
      </h1>
      <TabsNav :tabs="tabs" :activeTab="activeTab" />
      <router-view />
    </div>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLayout from '@/layouts/BaseLayout.vue';
import TabsNav from '@/components/ui/TabsNav/UITabsNav.vue';

export default {
  components: { BaseLayout, TabsNav },

  props: {
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      account: 'auth/account',
    }),
    currentRoute() {
      return this.$router.currentRoute.value.name;
    },
    tabs() {
      const tabs = [
        {
          name: 'Subscriptions',
          route: 'ManageSubscriptionsView',
          relatedRoutes: ['ManageSubscriptionView'],
        },
        { name: 'User Access', route: 'ManageUsersView' },
        {
          name: 'Order History',
          route: 'ManageOrdersView',
          relatedRoutes: ['ManageOrderView'],
        },
        { name: 'Addresses', route: 'ManageAddressesView' },
        { name: 'Payment Methods', route: 'ManagePaymentView' },
      ];

      if (this.account.has_api_access) {
        tabs.push({
          name: 'API Access',
          route: 'ManageAPIAccessView',
        });
      }

      return tabs;
    },
    activeTab() {
      return this.tabs.find(
        (tab) =>
          tab.route === this.currentRoute ||
          tab.relatedRoutes?.find((route) => route === this.currentRoute),
      );
    },
  },
};
</script>
