<script setup lang="ts">
import * as _ from 'lodash';
import { computed, onMounted, reactive, toRaw, watch } from 'vue';
import { useStore } from 'vuex';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import * as shapeUtils from './utils/shape';
import * as drawUtils from './utils/draw';
import * as markerUtils from '@/components/device/map/utils/marker.ts';
import * as layerUtils from '@/utils/map/layer';
import { clusterOptions } from '@/components/device/map/utils/marker.ts';
import { mapCenter } from '@/config/constants';
import { isAndroid } from '@/utils/user-agent';
import { useDevice } from '@/composables/useDevice';
import { usePosition } from '@/composables/usePosition.ts';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const { getPositionByDeviceId } = usePosition();
const deviceStore = useDevice();
const { devices } = storeToRefs(deviceStore);

const route = useRoute();

const props = defineProps<{
  view: string;
}>();

const data = reactive({
  dbClickTimer: null,
  center: mapCenter,
  map: null,
  mainLayers: [],
  layerControl: null,
  shape: null,
  shapes: L.featureGroup(),
  drawnItems: L.featureGroup(),
  drawControl: null,
  markers: null,
});

const canUseBleFeature = computed(() => store.getters['auth/canUseBleFeature']);
const viewportObj = computed(() => store.getters['geofence/viewportObj']);
const geofences = computed(() => store.getters['geofence/filtered']);
const geofence = computed(() => {
  return store.getters['geofence/byId'](+route.params.id);
});

let updateGeofencesDebounce = null;

onMounted(() => {
  setupLeafletMap();
  setupCluster();
  setActiveLayer(props.view);
  updateGeofencesDebounce = _.debounce(updateGeofences, 200);
});

watch(
  () => props.view,
  (newView, oldView) => {
    setActiveLayer(newView, oldView);
  },
);

watch(
  () => viewportObj.value,
  (n) => {
    if (['create', 'edit'].includes(props.view) && n) {
      const ne = n.getNorthEast();
      const sw = n.getSouthWest();
      const bounds = L.latLngBounds(
        L.latLng(ne.lat(), ne.lng()),
        L.latLng(sw.lat(), sw.lng()),
      );
      if (isAndroid()) {
        toRaw(data.map).fitBounds(bounds);
      } else {
        toRaw(data.map).flyToBounds(bounds, { duration: 1 });
      }
    }
  },
);

watch(
  () => geofences.value,
  (n, o) => {
    if (_.isEqual(n, o)) return;
    if (props.view === 'cluster') {
      updateGeofencesDebounce();
    }
  },
);

const setupLeafletMap = () => {
  const mainLayers = layerUtils.getLayers();
  const { id } = layerUtils.useLocalStorageLayer();
  data.mainLayers = mainLayers;

  data.map = L.map('map', {
    layers: [toRaw(data.mainLayers)[id]],
    center: toRaw(data.center),
    zoomControl: false,
    zoom: 5,
    minZoom: 1,
  });

  L.control.zoom({ position: 'topright' }).addTo(toRaw(data.map));
  data.layerControl = L.control
    .layers(toRaw(data.mainLayers), null, { position: 'topleft' })
    .addTo(toRaw(data.map));

  listenLayerChange();
  listenDraw();
};

const setActiveLayer = (newView, oldView?) => {
  if (newView === 'cluster') {
    if (data.markers) {
      toRaw(data.markers).clearLayers();
      toRaw(data.map).removeLayer(toRaw(data.markers));
    }
    if (oldView === 'single') {
      toRaw(data.map).removeLayer(toRaw(data.shape));
      setupCluster();
      return setClusterView();
      // return data.setClusterViewWithFly();
    }
    if (oldView === 'create') {
      toRaw(data.map).removeLayer(toRaw(data.drawnItems));
      toRaw(data.map).removeControl(toRaw(data.drawControl));
      toRaw(data.drawnItems).clearLayers();
      return setClusterView();
      // return data.setClusterViewWithFly();
    }
    if (oldView === 'edit') {
      toRaw(data.shapes).clearLayers();
      toRaw(data.map).removeLayer(toRaw(data.shapes));
      setupCluster();
      toRaw(data.drawnItems).clearLayers();
      toRaw(data.map).removeLayer(toRaw(data.drawnItems));
      toRaw(data.map).removeControl(toRaw(data.drawControl));
      return setClusterView();
    }

    setClusterView();
  }
  if (newView === 'single') {
    setupSingle();
    toRaw(data.map).removeLayer(toRaw(data.shapes));
    toRaw(data.shapes).clearLayers();
    toRaw(data.map).addLayer(toRaw(data.shape));

    if (oldView === 'create' || oldView === 'edit') {
      toRaw(data.shapes).clearLayers();
      toRaw(data.map).removeLayer(toRaw(data.shapes));
      toRaw(data.drawnItems).clearLayers();
      toRaw(data.map).removeLayer(toRaw(data.drawnItems));
      toRaw(data.map).removeControl(toRaw(data.drawControl));
      toRaw(data.markers).clearLayers();
      toRaw(data.map).removeLayer(toRaw(data.markers));
      setupDevicesCluster();
      setDevicesClusterView();
      setSingleView(data.shape);
      return;
    }

    setupDevicesCluster();
    setDevicesClusterView();
    setSingleView(data.shape);
  }
  if (newView === 'create') {
    setupDraw();
    setupDevicesCluster();
    setDevicesClusterView();
    if (oldView === 'cluster') return;
    if (oldView === 'single') {
      setupCluster();
    }
    setClusterView();
  }
  if (newView === 'edit') {
    if (oldView === 'cluster') {
      setupDevicesCluster();
      setDevicesClusterView();
      setupSingle();
      toRaw(data.map).removeLayer(toRaw(data.shapes));
      toRaw(data.shapes).clearLayers();
      toRaw(data.map).addLayer(toRaw(data.shape));
      setSingleView();
    }
    const shape = shapeUtils.convertLayerToShape(data.shape);
    setShape(shape);
    toRaw(data.map).removeLayer(toRaw(data.shape));
    toRaw(data.drawnItems).addLayer(toRaw(data.shape));
    setupDraw();
  }
};

const setupCluster = () => {
  geofences.value.forEach((geofence) => createClusterShape(geofence));
};

const setupSingle = () => {
  createSingleShape(geofence.value);
};
const setupDraw = () => {
  toRaw(data.map).addLayer(toRaw(data.drawnItems));
  data.drawControl = new L.Control.Draw({
    position: 'topright',
    draw: drawUtils.getDrawOptions(),
    edit: {
      featureGroup: toRaw(data.drawnItems),
    },
  });
  toRaw(data.map).addControl(toRaw(data.drawControl));
};

const setupDevicesCluster = () => {
  data.markers = L.markerClusterGroup(clusterOptions);
  devices.value.forEach((device) => createClusterMarker(device));
};

const setClusterView = () => {
  if (!geofences.value.length) return;
  toRaw(data.map).addLayer(toRaw(data.shapes));
  if (route?.query) {
    const { lat, lng } = route.query;
    if (lat && lng) {
      const { zoom } = layerUtils.useLocalStorageZoom();
      toRaw(data.map).setView([lat, lng], zoom);
      return;
    }
  }
  const bounds = toRaw(data.shapes).getBounds();
  toRaw(data.map).fitBounds(bounds);
};

const setSingleView = () => {
  const bounds = toRaw(data.shape).getBounds();
  toRaw(data.map).fitBounds(bounds);
};

const setDevicesClusterView = () => {
  toRaw(data.map).addLayer(toRaw(data.markers));
};

const createClusterShape = (geofence) => {
  const shape = shapeUtils.createShape(geofence);
  shape
    .on('click', () => {
      if (data.dbClickTimer !== null) {
        return;
      }
      data.dbClickTimer = setTimeout(() => {
        data.dbClickTimer = null;
        handleClusterShapeClick({ geofence });
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(data.dbClickTimer);
      data.dbClickTimer = null;
    });
  const content = shapeUtils.getTooltipContent({ geofence });

  L.tooltip({
    interactive: true,
    permanent: true,
    opacity: 0.7,
  })
    .on('click', () => {
      if (data.dbClickTimer !== null) {
        return;
      }
      data.dbClickTimer = setTimeout(() => {
        data.dbClickTimer = null;
        handleClusterShapeClick({ geofence });
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(data.dbClickTimer);
      data.dbClickTimer = null;
    })
    .setLatLng(L.latLng([geofence.lat, geofence.lng]))
    .setContent(content)
    .openOn(toRaw(data.shapes));

  shape.addTo(toRaw(data.shapes));
};

const createSingleShape = (geofence) => {
  const content = shapeUtils.getTooltipContent({ geofence });
  data.shape = shapeUtils.createShape(geofence);
  toRaw(data.shape).bindTooltip(content, {
    sticky: true,
    opacity: 0.7,
  });
};

const createClusterMarker = async (device) => {
  const position = getPositionByDeviceId(device.id);
  if (!position) return;
  const content = markerUtils.getPopupContent(device, position);
  const marker = markerUtils.createMarker(device, position, {
    isNewIcon: canUseBleFeature,
  });

  marker
    .bindPopup(content, {
      closeButton: false,
      minWidth: 200,
    })
    .openPopup()
    .on('mouseover', function () {
      const content = markerUtils.getPopupContent(device, position);
      this.getPopup().setContent(content);
      this.openPopup();
    })
    .on('click', (event) => {
      if (data.dbClickTimer !== null) {
        return;
      }
      data.dbClickTimer = setTimeout(() => {
        data.dbClickTimer = null;
        handleClusterMarkerClick({ event, device });
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(data.dbClickTimer);
      data.dbClickTimer = null;
    });

  toRaw(data.markers).addLayer(marker);
};

const handleClusterShapeClick = ({ geofence }) => {
  useRouter().push({
    name: 'GeofenceDetailsView',
    params: {
      id: geofence.id,
    },
  });
  createSingleShape(geofence);
};

const handleClusterMarkerClick = ({ event }) => {
  useRouter().push({
    name: 'DeviceDetailsView',
    params: {
      id: event.target.options.id,
    },
  });
};

const updateGeofences = () => {
  toRaw(data.shapes).clearLayers();
  toRaw(data.map).removeLayer(toRaw(data.shapes));
  setupCluster();
  toRaw(data.shapes).addTo(toRaw(data.map));
  setClusterView();
};

/*
 * Map Listeners
 */

const listenLayerChange = () => {
  const { set } = layerUtils.useLocalStorageLayer();
  toRaw(data.map).on('baselayerchange', (e) => {
    set(e.name);
  });
};

const listenDraw = () => {
  const { reset } = layerUtils.useLocalStorageZoom();
  toRaw(data.map)
    .on(L.Draw.Event.CREATED, (e) => {
      const layer = e.layer;
      toRaw(data.drawnItems).addLayer(layer);
      const shape = shapeUtils.convertLayerToShape(layer);
      setShape(shape);
      reset();
    })
    .on(L.Draw.Event.EDITED, (e) => {
      const layers = e.layers;
      layers.eachLayer((layer) => {
        const shape = shapeUtils.convertLayerToShape(layer);
        setShape(shape);
      });
      reset();
    })
    .on(L.Draw.Event.DRAWSTART, () => {
      toRaw(data.drawnItems).clearLayers();
      setShape(null);
    })
    .on(L.Draw.Event.DELETED, (e) => {
      const layers = e.layers;
      layers.eachLayer(() => {
        setShape(null);
      });
      reset();
    });
};

const setShape = (shape) => {
  store.dispatch('geofence/setShape', shape);
};
</script>
<template>
  <div class="z-0 size-full bg-gray-500" id="map" />
</template>
