import * as L from 'leaflet';
import * as _ from 'lodash';
import { reverseCoords } from '@/utils/coords';

export function createShape(geofence) {
  if (geofence.is_irregular) {
    const c = geofence.geo_json.coordinates[0].map(reverseCoords);
    return L.polygon([c], {
      id: geofence.id,
      latlng: {
        lat: geofence.lat,
        lng: geofence.lng,
      },
      color: '#58CE50',
      weight: 2,
      opacity: 0.7,
      fillColor: '#fff',
      fillOpacity: 0.7,
    });
  } else {
    return L.circle([geofence.lat, geofence.lng], {
      id: geofence.id,
      radius: geofence.radius || 0.1,
      latlng: {
        lat: geofence.lat,
        lng: geofence.lng,
      },
      color: '#58CE50',
      weight: 2,
      opacity: 0.7,
      fillColor: '#fff',
      fillOpacity: 0.7,
    });
  }
}

export function getTooltipContent({ geofence }) {
  const address = _.truncate(geofence.address, { length: 50 });
  return `
  <div>
    <div class="font-medium text-xs">${geofence.name}</div>
    <div class="text-[10px]">${address}</div>
  </div>
  `;
}

export function convertLayerToShape(layer) {
  const geoJson = layer.toGeoJSON();
  if (geoJson.geometry.type === 'Polygon') {
    return { is_irregular: 1, geo_json: JSON.stringify(geoJson.geometry) };
  } else {
    const radius = layer.getRadius();
    const [lng, lat] = geoJson.geometry.coordinates;
    return { is_irregular: 0, radius, lat, lng };
  }
}

export function createBleShape(coordinates, radius) {
  return L.circle([coordinates.lat, coordinates.lng], {
    radius: radius / 3.281 || 0.1,
    latlng: {
      lat: coordinates.lat,
      lng: coordinates.lng,
    },
    weight: 0,
    opacity: 0.7,
    fillColor: '#C4D9F6',
    fillOpacity: 0.7,
  });
}
