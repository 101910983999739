import * as _ from 'lodash';
import { formatDuration, formatSpeed, formatTime } from '@/filters';
import L from 'leaflet';
import * as layerUtils from '@/utils/map/layer';
import { getGoogleMapsLink } from '@/utils/map/google';
import * as chargeUtils from '@/utils/map/charge';

export function onEachPositionPointFeature(feature, layer) {
  if (feature.properties && feature.properties.popupContent) {
    layer.bindPopup(feature.properties.popupContent, {
      closeButton: false,
      minWidth: 200,
    });
  }
}

export function onEachStopCircleFeature(feature, layer) {
  if (feature.properties && feature.properties.tooltipContent) {
    layer.bindTooltip(feature.properties.tooltipContent, {
      permanent: true,
      direction: 'bottom',
    });
  }
}

function getPositionPopupContent(point) {
  const speed = formatSpeed(point.speed);
  const fix = point.fix ?? 'unknown';
  const updatedAt = formatTime(point.timestamp, 'E MMM d, yyyy h:mm aaa');
  const location = _.get(point, 'location', []);

  return `
  <div>
    <div class="text-[10px]">
      <div>
        <a href=${getGoogleMapsLink({
          lat: location[1],
          lng: location[0],
        })} target="_blank">${point.address}</a>
      </div>
      <div class="flex items-center">
        ${updatedAt}
        <span class="mx-1">-</span>
        ${speed}mph(${fix})
        <span class="mx-1">-</span>
        ${chargeUtils.getChargeEl(point.battery)}
      </div>
    </div>
  </div>
  `;
}

function getStopTooltipContent(point) {
  const startTime = point.start_time;
  const endTime = point.end_time;
  const duration =
    startTime && endTime
      ? formatDuration(startTime, endTime)
      : 'unknown period';
  const from = startTime ? formatTime(startTime, 'M/d h:mm aaa') : null;
  const to = endTime ? formatTime(endTime, 'M/d h:mm aaa') : null;
  const fromToLabel = from && to ? `${from} to ${to}` : 'Cannot load stops';
  return `
  <div class="text-[10px]">
    <div class="font-medium">Stopped for ${duration}</div>
    <div>${fromToLabel}</div>
  </div>
  `;
}

export function convertToPositionPointFC({ path }) {
  const features = path.map((point) => ({
    geometry: {
      type: 'Point',
      coordinates: point.location,
    },
    type: 'Feature',
    properties: {
      name: 'Position',
      popupContent: getPositionPopupContent(point),
      speed: formatSpeed(point.speed),
    },
    id: point.timestamp,
  }));
  return {
    type: 'FeatureCollection',
    features,
  };
}
export function convertToStopCircleFC(history) {
  const features = history.map((point) => ({
    geometry: {
      type: 'Point',
      coordinates: point.location,
    },
    type: 'Feature',
    properties: {
      name: 'Stop',
      tooltipContent: getStopTooltipContent(point),
      radius: point.radius,
    },
    id: point.timestamp,
  }));
  return {
    type: 'FeatureCollection',
    features,
  };
}

export function getPositionStyle(layerId, speed) {
  if (layerId === 'satellite') {
    return {
      color: '#222',
      fillColor: speed <= 0.5 ? '#C10202' : speed <= 4 ? '#FF9A35' : '#fff',
    };
  }
  if (layerId === 'map') {
    return {
      color: '#fff',
      fillColor: speed <= 0.5 ? '#C10202' : speed <= 4 ? '#FF9A35' : '#222',
    };
  }
  throw Error(`${layerId} is not supported`);
}

export function createPosition(point, latlng) {
  const { speed } = point.properties;
  const { id } = layerUtils.useLocalStorageLayer();
  const { color, fillColor } = getPositionStyle(id, speed);

  return L.circleMarker(latlng, {
    stroke: true,
    opacity: 0.9,
    weight: 1,
    radius: 6,
    fill: true,
    color,
    fillColor,
    fillOpacity: 1,
    pane: 'markerPane',
  });
}

export function createStop(point, latlng) {
  const { radius = 100 } = point.properties;
  return L.circle(latlng, {
    radius,
    color: 'black',
    weight: 1,
  });
}
