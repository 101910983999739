<script setup lang="ts">
import { useToast } from 'vue-toastification';
import DeviceChargeBadge from '../_common/DeviceChargeBadge.vue';
import DeviceNote from '../_common/DeviceNote.vue';
import DeviceStatusBadge from '../_common/DeviceStatusBadge.vue';
import {
  COMMON_REMOVE_ERROR,
  COMMON_REMOVE_SUCCESS,
  MESSAGE403,
} from '@/config/constants';
import { formatTimeAgoStrict } from '@/filters';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDevice } from '@/composables/useDevice.ts';
import { useGroup } from '@/composables/useGroup.ts';

const store = useStore();

const route = useRoute();
const router = useRouter();

const toast = useToast();

const { getById: getDeviceById } = useDevice();

const { getById: getGroupById, removeDevices } = useGroup();

defineEmits(['setEditItemId', 'resetEditItemId']);

const props = defineProps<{
  device: any;
  editItemId: any;
}>();

let position = computed(() => props.device?.position);

const name = computed(() => props.device?.name ?? '');
const address = computed(() => position.value?.address ?? '-');
const note = computed(() => props.device?.note);
const subscriptionId = computed(
  () => props.device?.subscription_id || props.device?.subscription?.id,
);
const subscriptionStatus = computed(
  () => props.device?.subscription_status || props.device?.subscription?.status,
);
const isSubscriptionPending = computed(
  () => subscriptionStatus.value === 'pending',
);
const timeAgo = computed(() => {
  const updatedAt = position.value?.timestamp;
  return updatedAt ? `${formatTimeAgoStrict(updatedAt)}` : 'no info';
});
const routeNameLC = computed(() => route.name.toLowerCase());

const getUIButtonActions = () => {
  const actions = [];
  const editDevice = {
    icon: 'PencilSquareIcon',
    label: 'Edit details',
    action: {
      mode: 'fn',
      fn: (id: number) => {
        router.push({
          name: 'DeviceDetailsView',
          params: { id },
        });
      },
    },
  };
  const deleteDeviceFromGroup = {
    icon: 'MinusCircleIcon',
    label: 'Remove from group',
    action: {
      mode: 'fn',
      fn: (id: number) => {
        if (!store.getters['auth/canManageGroups']) {
          toast.error(MESSAGE403);
          return;
        }
        const group = getGroupById(+route.params.id);
        const device = getDeviceById(id);
        const accountId = store.getters['auth/accountId'];
        store.dispatch('modal/open', {
          name: 'CommonConfirmationModal',
          params: {
            actionLabel: 'Remove',
            title: device.name,
            body: 'Are you sure you want to remove this tracker from this group?',
            onOk: () =>
              removeDevices(accountId, group.id, { device_ids: [device.id] }),

            successMessage: COMMON_REMOVE_SUCCESS,
            errorMessage: COMMON_REMOVE_ERROR,
          },
        });
      },
    },
  };
  if (routeNameLC.value.includes('device')) {
    actions.push(editDevice);
  }
  if (routeNameLC.value.includes('group')) {
    actions.push(editDevice);
    actions.push(deleteDeviceFromGroup);
  }
  return actions;
};
</script>

<template>
  <div>
    <div class="flex items-start justify-between gap-x-4">
      <div class="flex text-[15px] font-semibold text-lms_black-300">
        <span>{{ name }}</span>
      </div>
      <div class="ml-auto flex shrink-0 items-center">
        <div class="translate-y-[-px]">
          <DeviceChargeBadge
            v-if="position?.battery"
            :charge="position?.battery"
          />
          <DeviceStatusBadge
            v-if="!position"
            :subscription-id="subscriptionId"
            :subscription-status="subscriptionStatus"
            :updated-at="updatedAt"
          />
        </div>
        <UIIconButton
          class="absolute right-[9px] top-[11px] ml-[6px] md:right-[16px]"
          icon="EllipsisVerticalIcon"
          @click="$emit('setEditItemId', device.id)"
        >
          <UIActions
            :id="'device-actions-' + device.id"
            v-if="editItemId === device.id"
            v-click-outside="() => $emit('resetEditItemId')"
            :editItemId="editItemId"
            :actions="getUIButtonActions()"
          />
        </UIIconButton>
      </div>
    </div>
    <div class="flex justify-between gap-x-4">
      <div
        v-if="position && address"
        class="text-[13px] font-normal text-lgmx_gray-900"
      >
        {{ address }}
      </div>
      <div
        v-if="isSubscriptionPending"
        class="text-[12px] leading-4 text-lgmx_gray-900"
      >
        Your tracker is being provisioned. You will receive an email once the
        tracker has been assigned to your account and shipped.
      </div>

      <div
        v-if="position?.timestamp"
        class="ml-auto shrink-0 text-[13px] text-lgmx_gray-900"
      >
        {{ timeAgo }}
      </div>
    </div>
    <DeviceNote v-if="note" :note="note" class="mt-[10px]" />
  </div>
</template>
