<script setup lang="ts">
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { addMinutes } from 'date-fns';
import isNil from 'lodash/isNil';
import startCase from 'lodash/startCase';
import StringMask from 'string-mask';
import CommonProperty from '@/components/_common/CommonProperty.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonCollapse from '@/components/_common/CommonCollapse.vue';
import CommonPagination from '@/components/_common/CommonPagination.vue';
import NoData from '@/components/_common/NoData.vue';
import VDivider from '@/components/ui/VDivider.vue';
import UICombobox from '@/components/ui/UICombobox.vue';
import DeviceNetworkStatus from '@/components/device/list/DeviceListNetworkStatus.vue';
import DeviceSendCommand from '@/components/device/list/DeviceListSendCommand.vue';
import DeviceListDialog from '@/components/device/list/DeviceListDialog.vue';
import DeviceChargeBadge from '@/components/device/_common/DeviceChargeBadge.vue';
import DeviceEvent from '@/components/device/_common/DeviceEvent.vue';
import { COMMON_UPDATE_ERROR, COMMON_UPDATE_SUCCESS } from '@/config/constants';
import { formatTime } from '@/filters';
import { setTitle } from '@/utils/title';
import * as events from '@/events';
import { useDevice } from '@/composables/useDevice';
import { usePosition } from '@/composables/usePosition';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, unref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DeviceListSLAs from '@/components/device/list/DeviceListSLAs.vue'

const store = useStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();

const { getPositionByDeviceId } = usePosition();

const deviceStore = useDevice();
const { deviceView } = storeToRefs(deviceStore);

setTitle(`Tracker ${deviceView.value.name}`);

let position = ref(null);

// ref in template
const accountUsersListRef = ref(null);

const isSaving = ref(false);
const isLoading = ref(false);
const note = ref(null);
const deviceEvents = ref({
  isLoading: false,
  ids: [],
  page: 1,
  totalPages: 1,
});

const accountUsers = ref({
  ids: [],
  loading: false,
});

const accountId = computed(() => store.getters['auth/accountId']);
const account = computed(() => store.getters['auth/account']);
const deviceMeta = computed(() =>
  store.getters['deviceMeta/byId'](+route.params.id),
);
const devicePositionsHistoryDate = computed(
  () => store.getters['devicesPositionHistory/date'],
);
//deviceById: "device/byId",
const name = computed(() => deviceView.value?.name ?? '-');
const serial = computed(() => deviceView.value?.serial ?? '-');
const model = computed(() => deviceView.value?.model ?? {});
const title = computed(() => model.value?.title ?? '-');
const phone = computed(() => {
  let phone = deviceMeta.value?.phone;
  if (!phone) {
    return null;
  }
  const formatter = new StringMask('(000) 000-0000');
  if (phone.startsWith('+1')) {
    phone = phone.substring(2);
  }
  return formatter.apply(phone);
});
const isMissedLastUpdate = computed(() => {
  const expectedReportMinutes = model.value?.expected_report_minutes;
  if (!expectedReportMinutes) {
    return false;
  }

  const updatedAt = position.value?.timestamp;
  const lastChanceToUpdateAt = addMinutes(
    new Date(updatedAt),
    expectedReportMinutes,
  );
  return new Date() > lastChanceToUpdateAt;
});
const updated = computed(() => {
  const updatedAt = position.value?.timestamp;
  if (updatedAt) {
    return formatTime(updatedAt, 'MMM d, yyyy h:mm aa');
  }
  return 'unknown';
});
const address = computed(() => position.value?.address ?? '-');
const battery = computed(() => position.value?.battery);
const alarms = computed(() => {
  const alarms = [];
  if (position.value?.low_battery) {
    alarms.push('Low battery');
  }
  if (position.value?.charging) {
    alarms.push('Charging');
  }
  if (position.value?.sos) {
    alarms.push('SOS');
  }
  if (position.value?.overspeed) {
    alarms.push('Speeding');
  }
  if (alarms.length === 0) {
    alarms.push('None');
  }
  return alarms.join(', ');
});
const subscription = computed(() => deviceMeta.value?.subscription);
const subscriptionStatus = computed(
  () => deviceMeta.value?.subscription?.status ?? '-',
);
const activeSince = computed(() => {
  if (subscription.value?.start_at) {
    const startAt = new Date(subscription.value.start_at);
    return formatTime(startAt);
  }
  return '';
});
const subscriptionRenewal = computed(() => {
  if (subscription.value?.renewal_date) {
    const renewAt = new Date(subscription.value.renewal_date);
    return formatTime(renewAt);
  }
  return '';
});
const wasNoteChanged = computed(() => deviceView.value?.note !== note.value);
const deviceEventsItems = computed(() =>
  store.getters['deviceEvent/byIds'](deviceEvents.value.ids),
);
const accountUsersList = computed(() => {
  const users = store.getters['user/byIds'](accountUsers.value.ids);

  return users.map((user: any) => ({
    value: user.id,
    label: user.name,
  }));
});

onMounted(() => {
  if (!deviceView.value) {
    router.push({ name: 'NotFoundView' });
    return;
  }
  position.value = getPositionByDeviceId(deviceView.value.id);

  if (!position.value) {
    const toast = useToast();
    toast.warning('This tracker has no reported positions', {
      timeout: 3000,
    });
  }
  fetchDeviceMetaById({ id: +route.params.id });
  loadDeviceEvents();
  if (accountId.value) {
    fetchUsersByAccount();
  }
  events.trigger(events.names.TRACKER_VIEWED, unref(deviceView));
});

onUnmounted(() => {
  if (!devicePositionsHistoryDate.value) return;
  store.dispatch('devicePositionHistory/resetDate');
  store.dispatch('deviceStopHistory/resetDate');
});

watch(
  () => deviceEvents.value.page,
  () => loadDeviceEvents(),
);

const fetchDeviceMetaById = ({ id }) => {
  isLoading.value = true;
  store
    .dispatch('deviceMeta/fetchByAccountIdAndId', {
      accountId: accountId.value,
      id,
    })
    .then((res) => {
      note.value = res?.data?.data?.note;
      prePopulateDeviceUsersList(res?.data?.data?.users);
    })
    .finally(() => (isLoading.value = false));
};
const handleSaveName = (name: string) => {
  isSaving.value = true;
  const toast = useToast();
  store
    .dispatch('deviceMeta/updateById', {
      id: +route.params.id,
      params: { name },
    })
    .then(() => {
      deviceView.value.name = deviceMeta.value.name;
      toast.success(COMMON_UPDATE_SUCCESS);
      events.trigger(events.names.TRACKER_NAME_EDITED, unref(deviceMeta));
    })
    .catch(() => {
      toast.error(COMMON_UPDATE_ERROR);
    })
    .finally(() => {
      isSaving.value = false;
    });
};

const handleCancelUpdateName = () => {
  events.trigger(events.names.TRACKER_NAME_EDIT_CANCELLED, unref(deviceMeta));
};

const handleSaveNote = () => {
  if (isSaving.value) {
    return;
  }
  isSaving.value = true;
  const toast = useToast();
  store
    .dispatch('deviceMeta/updateById', {
      id: +route.params.id,
      params: { note: unref(note) },
    })
    .then(() => {
      deviceView.value.note = deviceMeta.value.note;
      toast.success(COMMON_UPDATE_SUCCESS);
    })
    .catch(() => {
      toast.error(COMMON_UPDATE_ERROR);
    })
    .finally(() => {
      isSaving.value = false;
    });
};

const loadDeviceEvents = () => {
  deviceEvents.value.isLoading = true;
  store
    .dispatch('deviceEvent/fetch', {
      deviceId: deviceView.value.id,
      params: {
        page: deviceEvents.value.page,
        per_page: 5,
      },
    })
    .then((res) => {
      deviceEvents.value.ids = res.data.data.map((item) => item.id);
      deviceEvents.value.totalPages = res.data.meta.last_page;
    })
    .finally(() => {
      deviceEvents.value.isLoading = false;
    });
};

const fetchUsersByAccount = () => {
  accountUsers.value.loading = true;
  store
    .dispatch('user/fetch')
    .then((res) => {
      accountUsers.value.ids = res.data.data.map((user) => user.id);
    })
    .catch(() => {
      accountUsers.value.ids = [];
    })
    .finally(() => {
      accountUsers.value.loading = false;
    });
};

const prePopulateDeviceUsersList = (users) => {
  if (!users) {
    return;
  }
  users?.forEach((user) =>
    accountUsersListRef.value?.setSelectedOptionQueitly({
      value: user.id,
      label: user.name,
    }),
  );
};

const updateDeviceUsers = (users) => {
  store
    .dispatch('deviceMeta/updateDeviceUsers', {
      id: deviceView.value.id,
      params: {
        users,
      },
    })
    .then(() => {
      toast.success('Device permissions were updated successfully.');
    })
    .catch((e) => {
      toast.error(e.response?.data?.message || 'An error occurred');
    });
};

const onUsersSelected = (users) => {
  updateDeviceUsers(users.map((user) => user.value));
};
</script>

<template>
  <CommonSearchBarSafeArea />
  <CommonCollapse
    :initial-open="true"
    title="Info"
    class="mb-1"
    :is-loading="isLoading"
  >
    <CommonProperty
      name="Name"
      :editable="name"
      :loading="isSaving"
      @onCancel="handleCancelUpdateName"
      @onSave="handleSaveName"
    >
      {{ name }}
    </CommonProperty>
    <CommonProperty name="Serial #">{{ serial }}</CommonProperty>
    <CommonProperty name="Model">{{ title }}</CommonProperty>
    <CommonProperty v-if="phone" name="Phone">{{ phone }}</CommonProperty>
    <CommonProperty name="Updated">
      <div>
        {{ updated }}
        <UITooltip class="inline" text="Missed last update">
          <ExclamationTriangleIcon
            v-if="isMissedLastUpdate"
            class="mx-1 inline size-4 cursor-help text-gpx_yellow-200"
          />
        </UITooltip>
      </div>
    </CommonProperty>
    <CommonProperty name="Address">{{ address }}</CommonProperty>
    <CommonProperty name="Alarms">{{ alarms }}</CommonProperty>
    <CommonProperty name="Battery">
      <template v-if="isNil(battery)">-</template>
      <DeviceChargeBadge v-else :charge="battery" class="translate-x-[-2px]" />
    </CommonProperty>
    <CommonProperty v-if="deviceView" name="Network">
      <DeviceNetworkStatus
        :device="deviceView"
        :position="position"
      ></DeviceNetworkStatus>
    </CommonProperty>
  </CommonCollapse>
  <CommonCollapse
    v-if="model?.has_temperature || model?.has_humidity"
    title="Temperature and Humidity SLAs"
    class="mb-1"
    :is-loading="isLoading"
  >
    <DeviceListSLAs :device-id="deviceView.id" />
  </CommonCollapse>
  <CommonCollapse title="Notes" class="mb-1" :is-loading="isLoading">
    <form class="overflow-hidden" @submit.prevent="handleSaveNote">
      <label class="font-medium" id="notes">Notes</label>
      <UITextarea class="w-full" v-model="note" placeholder="Your note here" />
      <VButton
        size="sm"
        class="float-right w-full sm:w-auto"
        :isLoading="isSaving"
        :isDisabled="isSaving || !wasNoteChanged"
      >
        Save
      </VButton>
    </form>
  </CommonCollapse>
  <CommonCollapse title="Subscription" class="mb-1" :is-loading="isLoading">
    <CommonProperty name="Status">
      {{ startCase(subscriptionStatus) }}
    </CommonProperty>
    <CommonProperty name="Active Since">{{ activeSince }}</CommonProperty>
    <CommonProperty name="Next Renewal">{{
      subscriptionRenewal
    }}</CommonProperty>
    <CommonProperty v-if="model?.has_voice" name="Voice">
      {{ account?.live_audio_minutes }} audio minutes available
    </CommonProperty>
  </CommonCollapse>
  <CommonCollapse
    v-if="deviceView"
    title="Commands"
    class="mb-1"
    :is-loading="isLoading"
  >
    <DeviceSendCommand :device-id="deviceView.id" />
    <VDivider />
    <DeviceListDialog :device-id="deviceView.id" />
  </CommonCollapse>
  <CommonCollapse
    title="Events"
    class="mb-1"
    :is-loading="deviceEvents.isLoading"
  >
    <NoData
      v-if="!deviceEventsItems.length"
      class="!p-0"
      title="No events yet"
    />
    <template v-else>
      <DeviceEvent
        v-for="event in deviceEventsItems"
        :key="event.id"
        :description="event.description"
        :date="event.created_at"
        class="mb-2"
      />
      <CommonPagination
        :totalPages="deviceEvents.totalPages"
        v-model="deviceEvents.page"
        class="mt-4"
      />
    </template>
  </CommonCollapse>
  <CommonCollapse
    title="Access"
    class="mb-1 !overflow-visible"
    :is-loading="isLoading && accountUsers.loading"
  >
    <div class="space-y-4">
      <p>
        Specify the users who can access this device data in addition to
        account's admins.
      </p>
      <UICombobox
        ref="accountUsersListRef"
        label="Users"
        placeholder="Search users by name"
        multiple
        :options="accountUsersList"
        @update:modelValue="onUsersSelected"
      />
    </div>
  </CommonCollapse>
</template>
