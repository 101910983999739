<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import ManageSubscriptionSelectPlan from '../_common/ManageSubscriptionSelectPlan.vue';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { computed, onMounted, reactive } from 'vue';
import { useDevice } from '@/composables/useDevice.ts';
import { useRouter } from 'vue-router';

const toast = useToast();
const store = useStore();

const { getById } = useDevice();

const emit = defineEmits(['close']);

const data = reactive({
  processing: false,
  requestSent: false,
  plan: {
    loading: false,
    months: 1,
  },
  paymentMethods: false,
  newSubscription: null,
});

const account = computed(() => store.getters['auth/account']);
const allPaymentMethods = computed(() => store.getters['paymentMethod/all']);
const activeModal = computed(() => store.getters['modal/active']);

const subscriptionId = computed(() =>
  parseInt(activeModal.value.params.subscriptionId, 10),
);

const subscription = computed(() =>
  store.getters['subscription/byId'](subscriptionId.value),
);

const device = computed(() => {
  const deviceId = subscription.value.devices[0].id;
  return getById(deviceId);
});

const productId = computed(() => device.value?.model?.product?.id);
const product = computed(() => store.getters['product/byId'](productId.value));
const paymentText = computed(() => {
  if (allPaymentMethods.value.length == 0) {
    return 'No active payment method was found. Please add a payment method via ';
  }

  let card = allPaymentMethods.value.find((payment) => payment.default);
  if (!card) {
    card = allPaymentMethods.value[0];
  }

  return `This will be automatically charged to your payment method ending in ${card.last4}. You can update your default payment method via `;
});

onMounted(() => {
  if (!product.value) {
    loadProduct();
  }

  if (!allPaymentMethods.value.length) {
    data.paymentMethods = true;
    store.dispatch('paymentMethod/fetch', account.value.id).finally(() => {
      data.paymentMethods = false;
    });
  }
});

const loadProduct = () => {
  data.plan.loading = true;
  store.dispatch('product/fetchById', productId.value).finally(() => {
    data.plan.loading = false;
  });
};

const handleSubmit = () => {
  if (data.processing) {
    return true;
  }

  if (data.requestSent) {
    closeModal();
    useRouter().replace({
      name: 'ManageSubscriptionView',
      params: { id: data.newSubscription.id },
      query: {
        include: 'devices,price',
      },
    });
    return;
  }

  data.processing = true;
  store
    .dispatch('subscription/reactivate', {
      id: subscriptionId.value,
      params: { months: data.plan.months },
    })
    .then((res) => {
      data.newSubscription = res.data.data;
      data.requestSent = true;
      data.processing = false;
    })
    .catch((err) => {
      data.processing = false;
      toast.error(err.response?.data?.message || 'An error occurred');
    });
};

const goToManagePaymentMethod = () => {
  closeModal();
};

const closeModal = () => {
  data.processing = false;
  data.requestSent = false;
  emit('close');
};
</script>
<template>
  <ModalLayout title="Reactivate Subscription" large>
    <div class="rounded-t-[10px] bg-white px-[24px] pt-[41px] sm:px-[40px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex size-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 size-[20px] text-gpx_gray-200"
        />
      </div>
      <div class="border-b border-b-gpx_gray-800 pb-[18px]">
        <div class="mb-[2px] text-sm font-medium text-gpx_gray-200">
          Activating
        </div>
        <div class="text-gpx_black-100 text-2xl font-semibold">
          Subscription
        </div>
      </div>
      <div class="mt-[25px] text-sm text-gpx_gray-100">
        <div
          v-if="!product || data.plan.loading || data.paymentMethods"
          class="text-center"
        >
          <UISpinner size="sm" class="mr-2" />
        </div>
        <template v-if="product && !data.plan.loading && !data.paymentMethods">
          <template v-if="!data.requestSent">
            <ManageSubscriptionSelectPlan
              :product="product"
              v-model="data.plan.months"
            />
            <p class="mt-3 text-[13px]">
              {{ paymentText }}
              <router-link
                :to="{ name: 'ManagePaymentView' }"
                class="cursor-pointer text-red-300"
                @click="goToManagePaymentMethod"
              >
                Manage Payment Methods
              </router-link>
              tab.
            </p>
          </template>
        </template>
        <template v-if="data.requestSent">
          <p class="font-medium">Payment is processing.</p>
          <p class="mt-3 text-[13px]">
            Subscription activation request has been initiated successfully.
            Status and Next Renewal date will be updated when payment is
            completed.
          </p>
        </template>
      </div>
    </div>
    <div
      class="flex w-full gap-x-[16px] rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]"
    >
      <VButton
        type="submit"
        variant="outline-primary"
        class="w-full"
        v-if="!data.processing && !data.requestSent"
        @click="closeModal"
      >
        Cancel
      </VButton>
      <VButton
        type="submit"
        variant="primary"
        class="w-full"
        @click="handleSubmit"
        :isDisabled="data.processing || allPaymentMethods.length === 0"
        :isLoading="data.processing"
      >
        {{ data.requestSent ? 'Okay' : 'Confirm' }}
      </VButton>
    </div>
  </ModalLayout>
</template>
