<template>
  <div class="z-0 h-full w-full bg-gray-500" id="map" />
</template>

<script>
import * as _ from 'lodash';
import { toRaw } from 'vue';
import L from 'leaflet';
import 'leaflet.markercluster';
import { mapCenter } from '@/config/constants';
import * as markerUtils from './utils/marker';
import * as shapeUtils from './utils/shape';
import * as layerUtils from '@/utils/map/layer';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      center: mapCenter,
      map: null,
      markers: null,
      shapes: L.featureGroup(),
    };
  },

  computed: {
    ...mapGetters({
      bleAnchorById: 'bleAnchor/byId',
      bleAnchors: 'bleAnchor/filtered',
    }),
  },

  watch: {
    bleAnchors: {
      handler() {
        this.updateCurrentPositions();
      },
    },
  },

  mounted() {
    this.setupLeafletMap();
    this.setupCluster();
    this.setView();
  },
  methods: {
    setupLeafletMap() {
      const mainLayers = layerUtils.getLayers();
      const { id } = layerUtils.useLocalStorageLayer();
      this.mainLayers = mainLayers;

      this.map = L.map('map', {
        layers: [mainLayers[id]],
        center: toRaw(this.center),
        zoomControl: false,
        zoom: 5,
        minZoom: 1,
      });

      L.control.zoom({ position: 'topright' }).addTo(toRaw(this.map));

      this.layerControl = L.control
        .layers(toRaw(this.mainLayers), null, {
          position: 'topleft',
        })
        .addTo(toRaw(this.map));

      this.listenLayerChange(toRaw(this.mainLayers));
    },
    setupCluster() {
      this.markers = L.markerClusterGroup(markerUtils.clusterOptions);
      toRaw(this.markers).setZIndex(1000);
      this.bleAnchors.forEach((bleAnchor) => {
        this.createClusterShape(bleAnchor);
        this.createClusterMarker(bleAnchor);
      });
    },
    setView() {
      toRaw(this.map).addLayer(toRaw(this.markers));
      toRaw(this.map).addLayer(toRaw(this.shapes));
      const bounds = toRaw(this.markers).getBounds();
      if (_.isEmpty(bounds)) return;
      toRaw(this.map).fitBounds(bounds, { maxZoom: 13 });
    },

    createClusterMarker(bleAnchor) {
      const getBleAnchor = () => this.bleAnchorById(bleAnchor.id);
      if (!bleAnchor.lat || !bleAnchor.lng) return;
      // const content = markerUtils.getPopupContent({ device });
      const marker = markerUtils.createBleAnchor(bleAnchor);

      marker
        .bindPopup('test', {
          closeButton: false,
          minWidth: 200,
        })
        .openPopup()
        .on('mouseover', function () {
          const bleAnchor = getBleAnchor();
          const content = markerUtils.getBleAnchorPopupContent({ bleAnchor });
          this.getPopup().setContent(content);
          this.openPopup();
        })
        .on('click', () => {
          if (this.dbClickTimer !== null) {
            return;
          }
          this.dbClickTimer = setTimeout(() => {
            this.dbClickTimer = null;
          }, 200);
        })
        .on('dblclick', () => {
          clearTimeout(this.dbClickTimer);
          this.dbClickTimer = null;
        });

      toRaw(this.markers).addLayer(marker);
    },
    createClusterShape(bleAnchor) {
      if (!bleAnchor) return;
      const coordinates = {
        lat: bleAnchor.lat,
        lng: bleAnchor.lng,
      };
      const radius = bleAnchor.radius;
      const shape = shapeUtils.createBleShape(coordinates, radius);
      shape.addTo(toRaw(this.shapes));
    },

    updateCurrentPositions() {
      toRaw(this.markers).clearLayers();
      toRaw(this.map).removeLayer(toRaw(this.markers));
      this.setupCluster();
      toRaw(this.map).addLayer(toRaw(this.markers));
    },
    listenLayerChange() {
      const { set } = layerUtils.useLocalStorageLayer();
      toRaw(this.map).on('baselayerchange', (e) => {
        set(e.name);
      });
    },
  },
};
</script>
