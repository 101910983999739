import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import values from 'lodash/values';
import isNull from 'lodash/isNull';
import service from '@/services';
import { DEVICE_CATEGORY } from '@/config/constants';
import { setAbortController } from '@/composables/useAbortController';
import { downloadFile } from '@/utils/blob.js';
import {
  getFilterResults,
  getSearchAndFilterResults,
  getSearchResults,
} from '@/utils/device-filter';
import { useToast } from 'vue-toastification';

export const useDevice = defineStore('device', () => {
  const deviceView = ref({});
  const devices = ref(new Map());
  const bleDevices = ref(new Map());
  const isLoading = ref(true);

  const search = ref('');
  const filter = ref({
    haveNotReportedDays: null,
    battery: null,
    missedUpdate: null,
    includeCellfixes: null,
    haveMovedDays: null,
    haveNotMovedDays: null,
  });

  const resultDeviceIds = ref([]);
  const deviceIdsFromMap = ref([]);
  const foundDeviceIds = ref([]);
  const selectedIds = ref([]);

  const activeTab = ref(DEVICE_CATEGORY.allTrackers);
  const mobileActiveTab = ref('right');

  const isAtLeastOneFilterApplied = computed(() =>
    values(filter.value).some((f) => !isNull(f)),
  );

  const setSearch = (value: string) => {
    search.value = value;
  };

  const setFilter = (value) => {
    filter.value = value;
  };

  const setMobileActiveTab = (tab: string) => {
    mobileActiveTab.value = tab;
  };

  const setActiveTab = (tab: string) => {
    activeTab.value = tab;
  };

  const setSelectedIds = (ids) => {
    selectedIds.value = ids;
  };

  const fillStoreDevicesFromResponse = (
    mapDevices: Map<number, any>,
    rawDevices?: any,
    isReset: boolean = false,
  ) => {
    if (isReset) {
      mapDevices.clear();
    }
    rawDevices = rawDevices || [];
    for (const device of rawDevices) {
      // @todo delete after refactoring api
      delete device.current_position;
      // delete device.group_ids;
      mapDevices.set(device.id, device);
    }

    return mapDevices;
  };

  const fetchByAccountId = async (accountId: number, params: any) => {
    isLoading.value = true;

    params['exclude_groups'] = true;

    const controller = setAbortController('device');
    const response = await service.get(`/accounts/${accountId}/device-list`, {
      params,
      signal: controller.signal,
    });

    if (response && response.status === 499) {
      isLoading.value = false;
      return response;
    }

    fillStoreDevicesFromResponse(devices.value, response.data.data);

    isLoading.value = false;
    return response;
  };

  const fetchBleSupportedDevices = async (accountId: number) => {
    isLoading.value = true;
    const response = await service.get(`/accounts/${accountId}/device-list`, {
      params: { ble_support: true },
    });

    fillStoreDevicesFromResponse(bleDevices.value, response.data.data);
    isLoading.value = false;
  };

  const fetchByAccountIdAndId = async (
    accountId: number,
    deviceId: number,
    params?: any,
  ) => {
    isLoading.value = true;
    const response = await service.get(
      `/accounts/${accountId}/devices/${deviceId}`,
      {
        params,
      },
    );

    const device = response?.data?.data;
    deviceView.value = device;
    if (device?.id) {
      devices.value.set(device.id, device);
    }
    isLoading.value = false;
    return device;
  };

  const fetchByCurrentAccountId = async (accountId: number, params: any) => {
    isLoading.value = true;
    const response = await service.get(`/accounts/${accountId}/devices`, {
      params,
    });

    fillStoreDevicesFromResponse(devices.value, response.data.data);

    isLoading.value = false;
    return response;
  };

  const fetchIdsByAccountIdAndParams = async (
    accountId: number,
    params: any,
  ): Promise<number[] | null> => {
    const controller = setAbortController('device');
    const response = await service.post(
      `/accounts/${accountId}/device-ids`,
      params,
      {
        signal: controller.signal,
      },
    );

    return response.data?.data;
  };

  const downloadByAccountId = async (fileName: string, params: any) => {
    try {
      const response = await service.post(
        '/report-builder/',
        {
          type: 'positions',
          response_type: 'csv',
          ...params,
        },
        {
          responseType: 'blob',
        },
      );

      return downloadFile({ res: response, fileName, extension: 'csv' });
    } catch (e) {
      useToast().error(
        JSON.parse(await e.response?.data?.text())?.message ||
          'An error occurred',
      );
    }
  };

  const downloadReportPositions = async (params: any) => {
    const response = await service.post('/report-builder/', params);

    return response.data.data;
  };

  const fetchTriggersById = async (id: number) => {
    const response = await service.get(`/devices/${id}/triggers`);
    return response?.data.data;
  };

  const updateTriggersById = async (payload: any) => {
    const response = await service.post(
      `/devices/${payload.device_id}/triggers`,
      payload,
    );
    return response?.data.data;
  };

  const applyFilter = (newFilter?: any) => {
    if (newFilter?.value) {
      filter.value = newFilter.value;
    }
    let foundIds = [];

    if (search.value && isAtLeastOneFilterApplied.value) {
      foundIds = getSearchAndFilterResults(devices.value, {
        search: search.value,
        filter: filter.value,
      });
    }
    if (isAtLeastOneFilterApplied.value && !search.value) {
      foundIds = getFilterResults(devices.value, filter.value);
    }
    if (search.value && !isAtLeastOneFilterApplied.value) {
      foundIds = getSearchResults(devices.value, search.value);
    }

    if (foundIds.length > 0) {
      resultDeviceIds.value = foundIds;
    } else if (!isAtLeastOneFilterApplied.value && !search.value) {
      resultDeviceIds.value = Array.from(devices.value.keys());
    } else {
      resultDeviceIds.value = [];
    }
  };

  const backupDevicesFromMap = (deviceIds) => {
    deviceIdsFromMap.value = deviceIds;
  };

  const getById = (id: number) => {
    return devices.value.get(id);
  };

  const addDevice = (device: any) => {
    return devices.value.set(device.id, device);
  };

  return {
    devices,
    bleDevices,
    deviceView,
    resultDeviceIds,
    foundDeviceIds,
    deviceIdsFromMap,
    selectedIds,
    search,
    filter,
    activeTab,
    mobileActiveTab,

    isAtLeastOneFilterApplied,
    isLoading,

    applyFilter,
    setSearch,
    setFilter,
    setMobileActiveTab,
    setActiveTab,
    setSelectedIds,
    fetchByAccountId,
    fetchByAccountIdAndId,
    fetchByCurrentAccountId,
    fetchBleSupportedDevices,
    fetchIdsByAccountIdAndParams,
    fetchTriggersById,
    updateTriggersById,
    downloadByAccountId,
    downloadReportPositions,
    fillStoreDevicesFromResponse,
    backupDevicesFromMap,
    getById,
    addDevice,
  };
});
